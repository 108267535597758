import { isProductionEnv } from "@moodys/cre-cpm.functions.environment"

export type ConsoleLevel = "error" | "warn" | "log"

// NOTE: this file should be autocontained without external deps to avoid eager errors with webpack module fed
const setupConsoleFilter = (terminal: any, level: ConsoleLevel, filters: string[]): any => {
  try {
    if (terminal[level]) {
      const originalTerm = terminal[level]
      const isMatchingFilter = (message: string): boolean =>
        filters.reduce((accum: boolean, filter: string): boolean => {
          return accum || message.indexOf(filter) !== -1
        }, false)

      // I disable this rule because there is no other way than override the console or at least is not obvious
      // In general this is a bad practice (overriding natives) but there is no other way to disable the webpack runtime warns
      // Checked the webpack source code before doing that
      // Error and log are not affected by this change they are native impl
      // Webpack file checked: ConsumeSharedRuntimeModule.js
      // eslint-disable-next-line no-param-reassign
      terminal[level] = (message: string): void => {
        if (!isProductionEnv() || !isMatchingFilter(message)) {
          originalTerm(message)
        }
      }
    }
  } catch (err) {
    // Do nothing just safe fn
  }
}

export default setupConsoleFilter

export const webpackConsoleFilters = ["Unsatisfied version", "shared singleton module"]
